import style from './Publications.styl';
import { Title } from '@components';
import { Section, StickyView } from '../../components';
import { toTopInterpolator } from '@utils/helpers';
import { devicesEnums, useDevice } from '@utils/hooks';
import * as images from './images';

export function Publications() {
  const device = useDevice();

  return (
    <StickyView autoHeight={device === devicesEnums.tablet}>
      {(proportion) => (
        <Section>
          <div className={style.publications}>
            <Title type="h2" mod={style.title}>Что о нас говорят?</Title>
            <div className={style.subtitle}>Нам самим было очень интересно прочесть.</div>
            <div className={style.list}>
              <a
                href="https://detifm.ru/fairy_tales/id/1597"
                className={style.article}
                target="_blank"
                rel="noreferrer"
              >
                <div className={style.text}>
                  Приключения с Чевостиком рады сообщить, что ваши любимые аудиоспектакли можно слушать на Детском Радио в эфире, а также на сайте
                </div>
                <img width={200} src={images.dflogo} srcSet={`${images.dflogo} 2x`} alt="" className={style.logo}/>
              </a>
              <a
                href="https://www.afisha.ru/article/5-prilozheniy-kotorye-pomogut-razvit-detskoe-lyubopytstvo/"
                className={style.article}
                style={toTopInterpolator(proportion, 0.3)}
                target="_blank"
                rel="noreferrer"
              >
                <div className={style.text}>
                  Приложение «Чевостик» предлагает детям 4–8 лет отправиться в путешествие по Стране знаний вместе со знакомым персонажем Чевостиком. Многие вспомнят его по шоу на «Детском радио» и мультфильмам для почемучек.
                </div>
                <img width={200} src={images.afisha} srcSet={`${images.afisha} 2x`} alt="" className={style.logo}/>
              </a>
              <a
                href="https://libertymag.ru/klipovoe-myshlenie-v-razvitii-detej"
                className={style.article}
                style={toTopInterpolator(proportion, 0.6)}
                target="_blank"
                rel="noreferrer"
              >
                <div className={style.text}>
                  Хорошим источником информации для детей будет ресурс, где собраны проверенные факты, есть ссылки на первоисточники, а в создании контента участвуют научные редакторы, психологи, методисты и учителя с большим стажем работы. Среди таких проектов — «Чевостик»...
                </div>
                <img style={{bottom:'20px'}} height={100} src={images.liberty} srcSet={`${images.liberty} 2x`} alt="" className={style.logo}/>
              </a>
            </div>
          </div>
        </Section>
      )}
    </StickyView>
  );
}
